<template>
    <v-container fluid>
        <v-snackbar id="snackbar" v-model="snackbar" :color="color" top :timeout="5000">
            <v-row>
                {{ text }}
                <v-spacer></v-spacer>
                <v-btn
                dark
                icon
                @click="snackbar = false"
                >
                <v-icon color="white">mdi-close</v-icon>
                </v-btn>
            </v-row>
        </v-snackbar>
        <div v-if="!loadingContract && contract" class="text-center">
            <div class="d-flex flex-wrap justify-space-between">
                <h1 class="blueDark--text mb-5">Contract: {{contract.name}} / {{contract.region.name}}</h1>
                <div class="mb-2">
                    <v-btn v-if="$store.state.user.role === 'ats_admin'" small class="mt-3" text color="blueLight" @click="$router.push({name: 'editContract', params: {id: contract.id}})"><v-icon left>mdi-file-document-edit</v-icon> Edit Contract</v-btn>
                    <v-dialog
                    v-model="dialog"
                    width="500"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-if="$store.state.user.role === 'ats_admin'" v-bind="attrs" v-on="on" small class="mt-3" text color="blueLight"><v-icon left>mdi-trash-can</v-icon> Delete Contract</v-btn>
                    </template>

                    <v-card>

                        <v-card-text class="text-center pt-5 body-1 blueDark--text">
                        Are you sure you want to delete this contract ?
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-btn color="warning" text @click="dialog = false">cancel</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="error" text @click="deleteItem">delete</v-btn>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>
                    <v-btn small class="mt-3" text color="blueLight" @click="$router.push({name: 'contractHistory', params: {id: contract.id}})"><v-icon left>mdi-file-clock</v-icon> View History</v-btn>
                </div>
            </div>
            <center>
              <div class="contract-embed my-10" v-html="contract.details"></div>
            </center>
        </div>
        <div v-else-if="!loadingContract && !contract">
            <p class="headline my-10 text-center"><v-icon>mdi-alert</v-icon> contract information isn't available!</p>
        </div>
    </v-container>
</template>

<script>
import { contractDetails, deleteContract, headers } from '../../links'

export default {
  data () {
    return {
      contract: null,
      loadingContract: true,
      dialog: false,
      loadingDeleteBtn: false,
      snackbar: false,
      color: '',
      text: ''
    }
  },
  methods: {
    deleteItem () {
      this.loadingDeleteBtn = true
      this.$http.delete(deleteContract(this.$route.params.id), { headers: headers(this.$cookies.get('userToken')) }).then(response => {
        this.$router.go(-1)
      }).catch(err => {
        this.snackbar = true
        this.color = 'warning'
        this.text = err.body.message
      }).finally(() => {
        this.loadingDeleteBtn = false
      })
    }
  },
  created () {
    this.$store.dispatch('setLoadingState', true)
    this.$http.get(contractDetails(this.$route.params.id), { headers: headers(this.$cookies.get('userToken')) }).then(response => {
      this.contract = response.body.data
    }).catch(err => {
      console.log(err)
    }).finally(() => {
      this.loadingContract = false
      this.$store.dispatch('setLoadingState', false)
    })
  }
}
</script>

<style>
  .contract-embed {
    width: 100%;
    overflow-x: auto;
  }
</style>
